<template>
  <v-app>
  <!-- color="indigo darken-2" -->
  <v-app-bar
    collapse
    collapse-on-scroll
    color="light-green"
    dark
    dense
    >
      <div id="nav">
        <router-link to="/"><i>Beta</i></router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/how">Quick Start</router-link>
      </div>
      <!-- auto space between Home/About and HelloWorld --> 
      <v-spacer></v-spacer>
      <router-view/>
      <!-- auto space between HelloWorld and Log in -->
      <v-spacer></v-spacer>
      <!-- drop down opeions for language support v-menu etc. -->
  </v-app-bar>
  <GmapMap
      :center="center"
      :zoom="12.5"
      map-type-id="roadmap"
      style="width: 100%; height: 700px"
      @click="updateCenter"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"        
      />
      <GmapCircle
      :center="center"
      :radius="5000"
      :options="{fillColor:'green',fillOpacity:0.1}"
      />
    </GmapMap>
    <p>Center: <b>Latitude</b> {{ center.lat.toFixed(2) }} , <b>Longitude</b> {{ center.lng.toFixed(2) }} </p>
    <p></p>

    <div id="msg" style="color:Red;">
      {{ message }}
    </div>

    <p> </p>
    <!-- only when valid data rows > 1 header row -->
    <div id="sum" style="color:Green;" v-if = 'markerschart.length > 1'>
      {{ total }}
      <p></p>
      <p>Source: <a href="https://www.flaringmonitor.org/" target="_blank"><span style='color:green'>Flaring Monitor</span></a></p>
    </div>
    <div id="statTable" v-if = 'stat_data.length > 1'>
    <p><b>Historical CO2 Flare Statistics within the Area</b></p>
    <p>Statistics for individual flares within, and the 5-km circle as a whole, over the time period since satellite observations began (2019).</p>
      <GChart
        type="Table"
        :data="stat_data"                                       
        :options="{ height: 120,
          width:  '80%'}"
        ref="gChart"
      />
      <p></p>
      <hr>
    </div>
    <div id="appHist" v-if = 'flared_hist.length > 3'>
        <GChart
          type="Histogram"
          :data="flared_hist"
          :options="{ height: 600,
            title: 'Area Flare Volume Spread',
            hAxis: {title: 'Thousand Cubic Feet per Month (Mcf/mo)',
              titleTextStyle: {
                color: '#1b9e77'}},
            vAxis: {title: 'Number of Flaring Events Observed',
              titleTextStyle: {
                color: '#1b9e77'}},
            legend: {
              position: 'none',
              alignment: 'start'
            },
            colors: ['#FF0000'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 12,
            hideBucketItems: 'false'}"
        />
      <p>Histogram depicts the distribution of monthly flare volumes per event-month, within the 5-km circle.</p>
    <hr>
    </div>
    <div id="operTable" v-if = 'oper_data.length > 1'>
    <p><b>Flare Volumes and Events by Operator</b></p>
      <GChart
        type="Table"
        :data="oper_data"                                       
        :options="{ height: 240,
          width:  '80%',
          sortAscending: 'false',
          sortColumn: 3}"
        ref="gChart"
      />
      <p></p>
    <hr>
    </div>
    <!-- only when valid data rows > 1 header row -->
    <div id="operChart" v-if = 'markerschart.length > 1'>
      <GChart
        type="ColumnChart"
        :data="markerschart"
        :options="chartOptions"
      />
      <p>Histogram depicts the number of flaring events by operator, beginning in 2019, within the 5-km circle.</p>
    <hr>
    </div>
    <div id="appTable" v-if = 'markersdata.length > 1'>
    <p><b>Geosearch Results within the 5-km Area</b></p>
      <GChart
        type="Table"
        :data="markersdata"                                       
        :options="{ height: 600,
          width:  '90%',
          sortAscending: 'false',
          sortColumn: 5}"
        ref="gChart"
      />
      <p>Table can be sorted by any column, and displays the <b><i>estimated CO2 volume</i></b> from each flare in units per month.</p>
      <p>Cluster Size indicates how many wells are feeding a single flare. Source: <a href="https://www.flaringmonitor.org/" target="_blank"><span style='color:green'>Flaring Monitor</span></a></p>    
    <hr>
    </div>
    <p> </p>
    <div id="appSankey" v-if = 'flared_sankey.length > 3 && markerschart.length > 2' >
        <p><b>Instantaneous Retrospective Pathway</b></p>
        <GChart
        :settings= "{ packages: ['sankey'] }"
          type= "Sankey"
          :data= "flared_sankey"
          :options= "sankey_Options"
        />
        <p></p>
        <p>The thickness of each pathway (hovering over the graph) reflects the individual flare volume. Color-coded "swaths" are cumulative volumes and grouped by operator.</p>
    <hr>
    </div>
    <div id="appChart2" v-if = 'marker2chart.length > 1'>
      <GChart
        type="ColumnChart"
        :data="marker2chart"
        :options="chartOption2"
      />
      <p></p>
      <p>Histogram shows the number of wells reporting production from the listed formations, within the 5-km area. Source: <a href="https://www.vue-shale-wells.com" target="_blank"><span style='color:green'>vue-shale-wells</span></a></p>    
    <hr>
    </div>
    <div id="xplotChart1" v-if = 'marker3tmp.length > 3'>
      <GChart
        type="ScatterChart"
        :data="marker3tmp"
        :options="xplotOption2"
      />
      <p>The bottom hole temperatures in the area are aggregated for stimulating new ideas, not linked to wells in the flaring reports.</p>
      <p>Source: <a href="http://geothermal.smu.edu/gtda/" target="_blank"><span style='color:green'>SMU Geothermal Laboratory (part of the National Geothermal Data System)</span></a></p>    
    <hr>
    </div>
    <div id="appHist1" v-if = 'marker2tvd.length > 3'>
      <GChart
          type="Histogram"
          :data="marker2tvd"
          :options="tvdOptions"
      />
      <p>Histogram shows the distribution of reported total vertical depths, for horizontal wells in the 5-km area. Source: <a href="https://www.vue-shale-wells.com" target="_blank"><span style='color:green'>vue-shale-wells</span></a></p>
      <p>Fewer wells per depth range, the formation and exact TVD are shown in the individual cell block.  For large number of wells, only the total number is displayed.</p>
    </div>
  <v-footer
      color="light-green darken-2"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          x-small
          color="white"
          text
          disabled
        >
        </v-btn>
        <v-col
          class="light-green darken-2 text-center white--text"
        >
          <small> Copyright &copy; {{  new Date().getFullYear() }} <strong> Shaleforce LLC</strong>. All rights reserved. </small>
        </v-col>
      </v-row>
    </v-footer>
</v-app>
</template>

<script>

import axios from 'axios';
import { GChart } from "vue-google-charts";

const percentile = require("percentile");

// return true if in range, otherwise false
function inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
}
// determine number of wells per formation
const occurrence = function (array) {
    "use strict";
    let result = {};
    if (array instanceof Array) {
        array.forEach(function (v, i) {
            if (!result[v]) {
                result[v] = [i];
            } else {
                result[v].push(i);
            }
        });
        Object.keys(result).forEach(function (v) {
            result[v] = result[v].length;
        });
    }
    return result;
};
// functions to get numeric values (array), average and median
const get_numbers = function (array) {
    "use strict";
    let result = {};
    if (array instanceof Array) {
        result = array.map(function(val) {
            return Number(val.slice(1, 2));
          });
    return result;
        }
};
/* const get_average = function (array) {
    "use strict";
    var cum = 0;
    if (array instanceof Array) {
        array.forEach( arr => {
            cum += arr;
        });
        }
    return cum/array.length
}; */
// average for array [x1, x2, x3, ...]
const get_average = arr => arr.reduce((a, b) => a + b, 0) /arr.length;
// average for grouped object [name: [x1], [x2], [x3], ...]
/* const averageArrays= arrays =>
      arrays.reduce((sum, a) => {
        a.forEach((v, i) => sum[i] = (sum[i] || 0) + v / arrays.length);
        return sum;
      }, []); */

export default {
  name: 'App',
  components: {
      GChart
    },
  data() {
    return {
      // default to Midland TX
      center: { lat: 32.00002, lng: -102.07446 },
      message: '',
      markers: [],
      markersdata: [],
      markerschart: [],
      marker2chart: [],
      // month_series: [],
      // markersopr: [],
      // markerscid: [],
      markersmth: [],
      markersvol: [],
      marker2fmt: [],
      marker2tvd: [],
      marker3tmp: [],
      flared_sankey: [],
      // flared_series: [],
      flared_hist: [],
      flared_stats: [],
      flarepm_stats: [],
      stat_data: [],
      oper_data: [],
      errors: [],
      places: [],
      currentPlace: null,
      /* tableOptions: {
        chart: {
          title: "Flaring Wells Summary",
          subtitle: "State, Operators, Assets and Flared Volumes"
        },
        height: 600,
        width:  '90%',
        sortAscending: 'false',
        sortColumn: 4,
      }, */
      /* tableEvents: {
        select: () => {          
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();          
          const onSelectionMeaasge = selection.length !== 0 ? 'row was selected' : 'row was diselected'
          alert(onSelectionMeaasge);
        }
      }, */

      chartOptions: {
        title: 'Area Flaring: Operators\' Summary',
        hAxis: {
          title: 'Operator',
          titleTextStyle: {
          color: '#1b9e77'
          }
        },
        vAxis:{
          title: 'Number of Flaring Events Observed', titleTextStyle: {color: '#1b9e77'}
        },
        legend: {
          position: 'none',
          alignment: 'start'
        },
        height: 600,
        colors: ['#4169e1'],
        minValue: 0,
      },
      chartSeries: {
        title: 'Area Flaring Events',
        hAxis: {
          title: 'Month',
          titleTextStyle: {
          color: '#1b9e77'
          }
        },
        vAxis:{
          title: 'Number of Flaring Observed', titleTextStyle: {color: '#1b9e77'}
        },
        legend: {
          position: 'none',
          alignment: 'start'
        },
        height: 600,
        colors: ['#800000'],
        minValue: 0,
      },
      chartSeries2: {
        title: 'Area Flaring Volumes',
        hAxis: {
          title: 'Month',
          titleTextStyle: {
          color: '#1b9e77'
          }
        },
        vAxis:{
          title: 'Flare Volumes (MTon)', titleTextStyle: {color: '#1b9e77'}
        },
        legend: {
          position: 'none',
          alignment: 'start'
        },
        height: 600,
        colors: ['#FF0000'],
        minValue: 0,
      },
      sankey_Options: {
        chart:{
          title: "Flare Volume Distribution",
        },
        height: 600,
        width:  '90%',
        sankey: {
          node: {
            width: 4,
            nodePadding: 50,
            label:{
              fontName: 'Avenir',
              color: '#505050',
              bold: true,
              fontSize: 20,
            }
          },
          link: {
            color:{
              fillOpacity: 1,
            },
           colorMode: 'gradient',
          },
        }
      },
      chartOption2: {
        title: 'Area Geology: Producing Formations',
        hAxis: {
          title: 'Geologic Formation',
          titleTextStyle: {color: '#1b9e77', bold: 'True'}
        },
        vAxis:{title: 'Number of Wells'},
        legend: 'none',
        height: 600,
        colors: ['#1b9e77'],
        minValue: 0,
      },
      // histogram tvd
      tvdOptions: {
        title: 'Area Geology: Unconventional Formation Depths',
        legend: { position: 'none' },
        orientation: 'vertical',
        hAxis: { title: 'Number of Wells' },
        vAxis:{
          title: 'True Vertical Depth (feet)',
          direction: '-1',
          titleTextStyle: {color: '#9b7653', bold: 'True'}
        },
        height: 600,
        colors: ['#9b7653'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },  /* separataion between bars */
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          /* minValue: 0,
          maxValue: 500, */
          hideBucketItems: 'false'
        }
      },
      xplotOption2: {
        title: 'Area Geothermal Gradient Model: Linear fit of Temperature vs Depth',
        orientation: 'vertical',
        hAxis: {title: 'Bottom Hole / Max Temperature (°F)',
                maxValue: 440,
                minValue: 0},
        vAxis: {title: 'Depth (feet)',
                minValue: 0,
                direction: '-1'},
        // legend: 'none',
        height: 600,
        width: '90%',
        colors: ['#00A36C'],
        trendlines: { 0: {
          color: '#FF6347',
          lineWidth: 6,
          opacity: 0.3,
          type: 'linear',
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      }
    }
  },
  // access local data
  /* mounted () {
    axios.get("./data/welldata.json")
    .then(response => (this.welldata = response.data)) 
  }, */
  // Learn more about this component at https://www.npmjs.com/package/vue2-google-maps
  methods: {
    async updateCenter(event) {
      // Remove existing markers
      this.markers = [];
      this.markersdata = [];
      this.markerschart = [];
      this.marker2chart = [];
      // this.month_series = [];
      // this.markersopr = [];
      // this.markerscid = [];
      this.markersmth = [];
      this.markersvol = [];
      this.flared_sankey = [];
      // this.flared_series = [];
      this.flared_hist   = [];
      this.flared_stats  = [];
      this.flarepm_stats = [];
      this.marker2fmt = [];
      this.marker2tvd = [];
      this.marker3tmp = [];
      this.stat_data  = [];
      this.oper_data  = [];
      this.message = '';
      this.total = '';
      this.errors = [];

      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      // min latitude set at the southern tip of Florida
      if (!(inRange(this.center.lat, 25.12, 49.38)
        && inRange(this.center.lng, -124.77, -66.95))) {
          this.message = 'Location falls outside the US Lower 48! Please try again.'
        } else { 

        try {
          "use strict";
          const markerlabel = [
            // { type: "number", label: "Cluster ID", id: "cid" },
            { type: "string", label: "State", id: "state" },
            { type: "string", label: "Operator", id: "operator" },
            { type: "string", label: "Cluster ID", id: "cid" },
            { type: "number", label: "Cluster Size (Wells)", id: "flare_wells" },
            { type: "string", label: "Date", id: "month" },
            { type: "number", label: "Flare Volume (Mcf/mo)", id: "flare_mcf" },
            { type: "number", label: "Flare Volume (MTon/mo)", id: "flare_ton" }
            ];
          const statslabel = [
            // { type: "number", label: "Cluster ID", id: "cid" },
            { type: "string", label: "Flare Volume Statistics (Mcf/mo)"},
            { type: "number", label: "P90"},
            { type: "number", label: "P50"},
            { type: "number", label: "P10"},
            { type: "number", label: "Swanson's Mean"},
            { type: "number", label: "Average"}
            ];
            const operslabel = [
            // { type: "number", label: "Cluster ID", id: "cid" },
            { type: "string", label: "Operator Flare Volumes (Mcf/mo)"},
            { type: "number", label: "Minmium"},
            { type: "number", label: "Average"},
            { type: "number", label: "Maximum"},
            { type: "number", label: "Flaring Events"}
            ];
            // console.log(markerdata);
            this.markersdata.push(markerlabel);
            this.stat_data.push(statslabel);
            //this.oper_data.push(operslabel);
            /* console.log('stat label: ',this.stat_data);
            console.log('oper label: ',this.oper_data); */
          
            // this.markerschart.push(['Content', 'Count', { role: 'style' }]);
            // this.markerschart.push(['Operator', 'Count']);
            // add axios
            this.marker2chart.push(['Formation', 'Count']);
            this.marker2tvd.push(['Formation', 'Depth']);
            this.marker3tmp.push(['Depth ft', 'Temperature F']);
            //
            // this.month_series.push(['Date', 'Count']);
            this.flared_sankey.push(['State', 'Operator', 'Flare Volume (MTon/mo)']);
            //this.flared_series.push(['Date', 'Flare Volume (Mcf/mo)']);
            //this.flared_hist.push(['Cluster', 'Flare Volume (Mcf/mo)']);
          
            const flaring = axios.post('https://pffa3nhs2h.execute-api.us-east-1.amazonaws.com/prod/',
            {
                lat: this.center.lat,
                lng: this.center.lng
            });
            const shale = axios.post('https://beoum955l8.execute-api.us-east-1.amazonaws.com/prod/',
            {
                lat: this.center.lat,
                lng: this.center.lng
            });
            const geothermal = axios.post('https://pmu39zkqxc.execute-api.us-east-1.amazonaws.com/prod/',
            {
                lat: this.center.lat,
                lng: this.center.lng
            });
            axios.all([flaring, shale, geothermal])
              .then(axios.spread((...responses) => {
                responses[0].data.map((location) => {
                  const point = JSON.parse(location.geoJson.S)
                  const marker = {
                    lat: point.coordinates[1],
                    lng: point.coordinates[0],
                    state: location.state.S,
                    operator: location.operator.S,
                    cid: location.cid.N,
                    flare_wells: location.flare_wells.N,
                    month: location.month.S,
                    flare_mcf: location.flare_mcf.N,
                    flare_ton: location.flare_ton.N
                  }
                  this.markers.push({ position: marker });

                  // console.log(markerdata);
                  this.markersdata.push([marker.state, marker.operator, marker.cid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""),
                    parseInt(marker.flare_wells), marker.month,
                    parseInt(marker.flare_mcf), parseInt(marker.flare_ton)]);
                  if (marker.flare_ton > 0) {
                    this.flared_sankey.push([marker.state, marker.operator, parseInt(marker.flare_ton)]);
                    this.markersvol.push([marker.month, parseInt(marker.flare_mcf)]);
                    this.flared_hist.push([marker.operator, parseInt(marker.flare_mcf)]);
                  }
                  // label only does not work for histogram
                  // this.markersopr.push(marker.operator);
                  // this.markerscid.push(marker.cid);
                  // this.markersmth.push(marker.month);
                })
                // if no markers send message
                // console.log(this.flared_sankey.length);
                if (this.markers.length == 0) {
                  this.message = 'No flaring clusters around this location. Try another spot.'
                } else {
                  this.total = 'Way to go. Total of ' + this.markers.length +  ' flaring observed.'
              //
              // extract number of occurrence cluster id
              /* var entries = Object.entries(occurrence(this.markerscid));
              console.log("# clusters: ", entries.length); */
              // determine how many months of flared volumes
              // var entries = Object.entries(occurrence(this.markersmth));
              // entries.sort();
              // console.log("# months: ", entries.length);
              // console.log(entries);
              /* for (var i = 0; i < entries.length; i++) {
                this.month_series.push(entries[i]);
              } */
              // sum and reduce flared volumes for month Observed
              const temp = this.markersvol.reduce(function (obj, cur) {
                const total_vol = obj[cur[0]] || 0;
                obj[cur[0]] = total_vol + cur[1];
                return obj;
              }, {} );
              const volumes = Object.keys(temp).map( function (key) { return [key, temp[key]]; });
              //console.log(volumes);
              // sort by month string
              /* volumes.sort(function (a, b) {
                  return a[0].localeCompare(b[0]);
              }); */
              const sum = this.markersvol.map(function(value) { return value[1]; }).reduce((a, b) => {
                  return a + b;
                });
                this.total += ' Aggregate flare volume ' + parseInt(sum).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +  ' Mcf.'
              const ave = (sum / volumes.length) || 0;
                this.total += ' Average flare ' + parseInt(ave).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +  ' Mcf/mo' +
                ' over ' + parseInt(volumes.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' months.'
              // Array.prototype.push.apply(this.flared_series, volumes);
              // console.log(this.flared_series); 
              //this.flared_stats = percentile ([10, 50, 90], get_numbers(this.flared_hist.slice(1)));
              this.flared_stats = percentile ([10, 50, 90], get_numbers(this.flared_hist));
              let stats_arr = this.flared_stats;
              this.flared_stats[3] = parseInt(0.3*stats_arr[0] + 0.4*stats_arr[1] + 0.3*stats_arr[2]);
              //this.flared_stats[4] = parseInt(get_average(get_numbers(this.flared_hist.slice(1))));
              this.flared_stats[4] = parseInt(get_average(get_numbers(this.flared_hist)));
              stats_arr = this.flared_stats;
              stats_arr.splice(0, 0, 'Individual Flares');
              this.stat_data.push(stats_arr);

              // this.flarepm_stats = percentile ([10, 50, 90], get_numbers(this.flared_series.slice(1)));
              this.flarepm_stats = percentile ([10, 50, 90], get_numbers(volumes));
              stats_arr = this.flarepm_stats;
              this.flarepm_stats[3] = parseInt(0.3*stats_arr[0] + 0.4*stats_arr[1] + 0.3*stats_arr[2]);
              // this.flarepm_stats[4] = parseInt(get_average(get_numbers(this.flared_series.slice(1))));
              this.flarepm_stats[4] = parseInt(get_average(get_numbers(volumes)));
              stats_arr = this.flarepm_stats;
              stats_arr.splice(0, 0, 'Area Aggregate');
              this.stat_data.push(stats_arr);
              // console.log(this.stat_data);

              //const grouped = this.flared_hist.slice(1).reduce((acc, a) => {
              const grouped = this.flared_hist.reduce((acc, a) => {
                acc[a[0]] = acc[a[0]] || [];
                acc[a[0]].push(a.slice(1));
                return acc;
                }, {});
              
              // this.flared_series.splice(0, 0, ['Date', 'Flare Volume (Mcf/mo)']);
              this.flared_hist.splice(0, 0, ['Cluster', 'Flare Volume (Mcf/mo)']);
              //console.log('grouped: ', grouped);
              //this.oper_data = Object.entries(grouped).map(([name, arrays]) => [name, Math.min(...arrays), ...averageArrays(arrays), Math.max(...arrays), arrays.length]);
              this.oper_data = Object.entries(grouped).map(([name, arrays]) => [name, Math.min(...arrays), get_average(arrays.flat()), Math.max(...arrays), arrays.length]);
              // format integer for table
              this.oper_data = this.oper_data.map(d => [d[0], d[1], d[2].toFixed(0),d[3], d[4]]);
              this.markerschart = this.oper_data.map(d => [d[0], d[4]]);
              this.oper_data.splice(0, 0, operslabel);
              this.markerschart.sort();
              this.markerschart.splice(0, 0, ['Operator', 'Count']);
              // console.log("select cols: ", this.markerschart);
              // console.log('oper data: ', this.oper_data);
              /* let stat3p = Object.entries(grouped).map(([name, arrays]) => [name, Math.min(...arrays), percentile([10, 50, 90], get_numbers(...arrays)), Math.max(...arrays)]);
              console.log('stat 3Ps: ', stat3p); */
              }
              if (this.markers.length > 1) responses[1].data.map((location) => {
                const point = JSON.parse(location.geoJson.S)
                const marker2 = {
                  lat: point.coordinates[1],
                  lng: point.coordinates[0],
                  formation: location.formation.S,
                  tvd: location.tvd.N,
                  lateral:location.lateral_length.N
                }
              //console.log(marker2.formation);
              if (marker2.lateral > 800) this.marker2fmt.push(marker2.formation);
              // extract tvd histogram
              if (marker2.lateral > 800 && marker2.tvd > 1000) this.marker2tvd.push([marker2.formation, marker2.tvd]);
              })
            // check for valid formation count
            if (this.marker2fmt.length > 1) {
            // extract number of occurrence
            // console.log(occurrence(this.marker2fmt));
              let entries = Object.entries(occurrence(this.marker2fmt));
              entries.sort();
              // QC formations console.log(entries);
              for (var i = 0; i < entries.length; i++) {
                this.marker2chart.push(entries[i]);
              }
            }
              if (this.markers.length > 1) responses[2].data.map((location) => {
                const point = JSON.parse(location.geoJson.S)
                const marker3 = {
                  lat: point.coordinates[1],
                  lng: point.coordinates[0],
                  eff_temp: location.eff_temp.N,
                  depth: location.depth.N,
                }
                if (marker3.eff_temp > 0 & marker3.depth > 0)
                  this.marker3tmp.push([parseFloat(marker3.depth) * 3.28, (parseFloat(marker3.eff_temp) * 9/5) + 32]);

                // label only does not work for histogram

              })
          }))   // end (axios.spread( (responses) => {}
          // .catch errors required
         .catch(e => {
            this.errors.push(e)
          })
        }   // end try {}
        // try {} catch block {}
       catch (err) {
          console.log(err)
       }
      }
    }
  }  
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
